import { all, put, call, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getlocalstorage } from "./../../../constants/local_storage";
import * as actions from "./actions";

function* loadRmcConcreteTripReport(action) {
  const ACCESSTOKEN = localStorage.getItem("access-token");
  const COMPANY_ID = getlocalstorage(
    localStorage.getItem("profile"),
    "company_id"
  );
  const {
    data: {
      vehicle_visibility,
      start_at,
      end_at,
      vehicles,
      is_minimal,
      site_name,
      site_code,
      plants,
      type,
    },
  } = action.payload;

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_API_REPORT_GOLANG_MONGO}/reports/go/rmcconcretetrip`,
      {
        vehicle_visibility: vehicle_visibility
          .split(",")
          .map((item) => parseInt(item)),
        start_at,
        end_at,
        vehicles,
        company_id: `${COMPANY_ID}`,
        type_file: "",
        is_minimal,
        site_name,
        site_code,
        plants,
        type,
      },
      { headers: { Authorization: `Bearer ${ACCESSTOKEN}` } }
    );
    yield put(actions.loadRmcConcreteTripReport.success(res.data));
    window.location = res.data.result;
  } catch (err) {
    console.log("error", err);
    yield put(actions.loadRmcConcreteTripReport.failure());
  }
}

export default function* watchSecBySec() {
  yield all([
    takeEvery(
      actions.LOAD_RMC_CONCRETE_TRIP_REPORT.REQUEST,
      loadRmcConcreteTripReport
    ),
  ]);
}
export { loadRmcConcreteTripReport };
