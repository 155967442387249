import axios from "axios";

// Define your username and password
const username = "adasfreewill";
const password = "P@ssw0rd";

// Encode the username and password for the Basic Auth header
const authHeader =
  "Basic " + Buffer.from(`${username}:${password}`).toString("base64");

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_GO_ENDPOINT}/adas-dashboard`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authHeader,
  },
});

const axiosClientFromWeb = axios.create({
  baseURL: `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  },
});

// Centralized error handling function (example)
const handleError = (error, callback) => {
  // Handle error here or pass it to callback

  console.error("API Error:", error, authHeader);
  //   callback(error.response?.status, error.response?.data);
};

export async function loadEvent(params, callback) {
  try {
    const res = await axiosClient.get("/dashboard/eventtype");
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadVideoAndBookmark(params, callback) {
  try {
    const res = await axiosClient.post("/dashboard/videos", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadReport(params, callback) {
  try {
    const res = await axiosClient.post("/reports/event", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function createBookmark(params, callback) {
  try {
    const res = await axiosClient.post("/bookmarks/create", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function deleteBookmark(params, callback) {
  let { user_id, vehicle_id } = params;
  try {
    const res = await axiosClient.delete(
      `/bookmarks/delete/${user_id}/${vehicle_id}`
    );
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function getPhoneNumber(params, callback) {
  try {
    const res = await axiosClientFromWeb.post("/getsimno", params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadVehicleType(params, callback) {
  try {
    const res = await axiosClientFromWeb.post(
      "/getgroupvehiclewithvalue",
      params
    );
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadDetail(vid, params, callback) {
  try {
    const res = await axiosClient.post(`/detail/vehicle/${vid}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export async function loadDetailTable(vid, params, callback) {
  try {
    const res = await axiosClient.post(`/detail/table/${vid}`, params);
    callback(200, res);
  } catch (error) {
    handleError(error, callback);
  }
}

export default axiosClient;
