import { all, put, call, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from './actions'

// *load table log ประเมินคุณภาพ
function* monitorSDCardTableLog(action) {
  const {
    data: {
      page,
      pageSize,
      vehicleVisibility,
      filterObj,
      canFilterCompany,
      accessToken,
    },
  } = action.payload
  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/monitor-sdcard/log`,
      {
        page,
        pageSize,
        vehicleVisibility,
        filterObj,
        canFilterCompany,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.monitorSDCardTableLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.monitorSDCardTableLog.failure())
  }
}

// *ดึงข้อมูลเพื่อแสดง chart
function* monitorSDCardChartLog(action) {
  const {
    data: { vehicle_id, date, hardware_id, type, accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.post,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/monitor-sdcard/info-log`,
      {
        vehicle_id,
        date,
        hardware_id,
        type,
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.monitorSDCardChartLog.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.monitorSDCardChartLog.failure())
  }
}

// *ดึงข้อมูลเพื่อแสดง company list
function* monitorSDCardCompanyList(action) {
  const {
    data: { accessToken },
  } = action.payload

  try {
    const res = yield call(
      axios.get,
      `${process.env.REACT_APP_LARAVEL_API_ENDPOINT}/monitor-sdcard/company-list`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    )

    yield put(actions.monitorSDCardCompanyList.success(res.data))
  } catch (err) {
    console.log(err.message)
    yield put(actions.monitorSDCardCompanyList.failure())
  }
}

export default function* watchMonitorSDCard() {
  yield all([
    takeEvery(actions.MONITOR_SDCARD_TABLE_LOG.REQUEST, monitorSDCardTableLog),
    takeEvery(actions.MONITOR_SDCARD_CHART_LOG.REQUEST, monitorSDCardChartLog),
    takeEvery(
      actions.MONITOR_SDCARD_COMPANY_LIST.REQUEST,
      monitorSDCardCompanyList
    ),
  ])
}
export {
  monitorSDCardTableLog,
  monitorSDCardChartLog,
  monitorSDCardCompanyList,
}
