import { createTypes, createAction } from "lib/action";

const LOAD_EVENT = createTypes("event", "load");
const LOAD_VEHICLE_EVENT = createTypes("vehicle_event", "load");
const LOAD_VEHICLE_TYPE_EVENT = createTypes("vehicletype_event", "load");
const LOAD_POLICY_EVENT = createTypes("policy_event", "load");
const LOAD_EVENT_EVENT = createTypes("event_event", "load");
const LOAD_VEHICLE_BY_POLICY_EVENT = createTypes(
  "vehicle_by_policy_event",
  "load"
);
const LOAD_VEHICLE_EVENT_BY_GROUP = createTypes(
  "vehicle_event_by_group",
  "load"
);

const loadEvent = {
  request: (data, loading) =>
    createAction(LOAD_EVENT.REQUEST, { data, loading }),
  success: (data) => createAction(LOAD_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_EVENT.FAILURE),
};

const loadPolicy = {
  request: () => createAction(LOAD_POLICY_EVENT.REQUEST, {}),
  success: (data) => createAction(LOAD_POLICY_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_POLICY_EVENT.FAILURE),
};

const loadEvents = {
  request: () => createAction(LOAD_EVENT_EVENT.REQUEST, {}),
  success: (data) => createAction(LOAD_EVENT_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_EVENT_EVENT.FAILURE),
};

const loadVehiclebypolicy = {
  request: (policyID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_BY_POLICY_EVENT.REQUEST, {
      policyID,
      loading_vehicle,
    }),
  success: (data) =>
    createAction(LOAD_VEHICLE_BY_POLICY_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_BY_POLICY_EVENT.FAILURE),
};

const loadVehicle = {
  request: (vehicletypeID, loading_vehicle, is_icing) =>
    createAction(LOAD_VEHICLE_EVENT.REQUEST, {
      vehicletypeID,
      loading_vehicle,
      is_icing,
    }),
  success: (data) => createAction(LOAD_VEHICLE_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_EVENT.FAILURE),
};

const loadVehiclebygroupid = {
  request: (vehicletypeID, loading_vehicle) =>
    createAction(LOAD_VEHICLE_EVENT_BY_GROUP.REQUEST, {
      vehicletypeID,
      loading_vehicle,
    }),
  success: (data) =>
    createAction(LOAD_VEHICLE_EVENT_BY_GROUP.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_EVENT_BY_GROUP.FAILURE),
};

const loadVehicleType = {
  request: () => createAction(LOAD_VEHICLE_TYPE_EVENT.REQUEST, {}),
  success: (data) => createAction(LOAD_VEHICLE_TYPE_EVENT.SUCCESS, { data }),
  failure: () => createAction(LOAD_VEHICLE_TYPE_EVENT.FAILURE),
};

export {
  loadEvent,
  LOAD_EVENT,
  loadVehicle,
  LOAD_VEHICLE_EVENT,
  loadVehicleType,
  LOAD_VEHICLE_TYPE_EVENT,
  loadPolicy,
  LOAD_POLICY_EVENT,
  loadEvents,
  LOAD_EVENT_EVENT,
  loadVehiclebypolicy,
  LOAD_VEHICLE_BY_POLICY_EVENT,
  loadVehiclebygroupid,
  LOAD_VEHICLE_EVENT_BY_GROUP,
};
