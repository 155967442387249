import { createTypes, createAction } from "lib/action";

const LOAD_RMC_CONCRETE_TRIP_REPORT = createTypes(
  "rmc_concrete_trip_report",
  "load"
);

const loadRmcConcreteTripReport = {
  request: (data) =>
    createAction(LOAD_RMC_CONCRETE_TRIP_REPORT.REQUEST, { data }),
  success: (data) =>
    createAction(LOAD_RMC_CONCRETE_TRIP_REPORT.SUCCESS, { data }),
  failure: () => createAction(LOAD_RMC_CONCRETE_TRIP_REPORT.FAILURE),
};

export { loadRmcConcreteTripReport, LOAD_RMC_CONCRETE_TRIP_REPORT };
