import React, {
  useState,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { Row, Col, Spin, Table, Tooltip, Icon, Pagination } from "antd";
import { icons } from "./Constant";
import ModalInfo from "./ModalInfo";
import ModalVideo from "./ModalVideo";
import { loadReport } from "../fetchAPI/apiClient";
import ModalPhoneNumber from "./ModalPhoneNumber";
import "../styles/Reports.css"; // Import the custom CSS for styling the sorter icons

const Reports = forwardRef(({ auth, filterObj }, ref) => {
  const [loading, setLoading] = useState(false);
  const [visibleVideo, setVisibleVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState(false);
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    page_size: 10,
  });
  const [sorter, setSorter] = useState({
    field: "latestNoti",
    order: "desc",
  });
  const [info, setInfo] = useState({ imei: "", plateNo: "", code: "" });

  useEffect(() => {
    filterColumn();
  }, [filterObj]);

  const filterColumn = () => {
    const columnConfig = [
      {
        types: ["600,601"],
        key: "vehicleCloseToTheFront",
        iconKey: "vehicleCloseToTheFrontReport",
      },
      {
        types: ["602,603,608,609"],
        key: "straddlingLanesSuddenlyLeavingLanes",
        iconKey: "straddlingLanesSuddenlyLeavingLanesReport",
      },
      {
        types: ["606,607"],
        key: "nearPedestrians",
        iconKey: "nearPedestriansReport",
      },
      {
        types: ["618,619"],
        key: "sleepyYawning",
        iconKey: "sleepyYawningReport",
      },
      { types: ["620,621"], key: "call", iconKey: "callReport" },
      {
        types: ["624,625"],
        key: "doNotLookAtTheRoad",
        iconKey: "doNotLookAtTheRoad",
      },
      { types: ["622,623"], key: "smoke", iconKey: "smokeReport" },
      {
        types: ["626,627"],
        key: "notFoundDriver",
        iconKey: "notFoundDriverReport",
      },
      {
        types: ["704,705"],
        key: "cameraBlocked",
        iconKey: "cameraBlockedReport",
      },
    ];

    const newColumns = [...tableColumns];

    columnConfig.forEach(({ types, key, iconKey }) => {
      if (types.some((type) => filterObj.type.includes(type))) {
        newColumns.push({
          title: (
            <Tooltip title={icons[iconKey].label}>
              {icons[iconKey].icon({ fill: "black" })}
            </Tooltip>
          ),
          align: "center",
          dataIndex: key,
          sorter: true, // Keep the default Ant Design sorter
          key: key,
        });
      }
    });

    // Add the fixed "Action" column at the end
    newColumns.push({
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <Row gutter={[6, 6]}>
          <Col span={24} align="center">
            <Tooltip
              placement="top"
              title={icons["videoCallReport"].label}
              arrowPointAtCenter
            >
              <Icon
                type="phone"
                style={{
                  fontSize: 16,
                  color: "black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handlePhoneNumber({
                    imei: record.hardwareId,
                    plateNo: record.plateNo,
                    code: record.code,
                  });
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    });

    setColumns(newColumns);
  };

  const tableColumns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      fixed: "left",
      width: 40,
      align: "center",
      render: (text, record, index) => <>{text}</>,
    },
    {
      title: "พาหนะ",
      dataIndex: "plateNo",
      key: "plateNo",
      fixed: "left",
      width: 100,
    },
    {
      title: "รหัสพาหนะ",
      dataIndex: "code",
      key: "code",
      fixed: "left",
      width: 200,
    },
    {
      title: "รายชื่อคนขับ",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "แจ้งเตือนล่าสุด",
      dataIndex: "latestNoti",
      key: "latestNoti",
    },
    {
      title: "จำนวนครั้งทั้งหมด",
      dataIndex: "totalNumberOfTime",
      key: "totalNumberOfTime",
    },
  ];

  const getReport = (params, loading) => {
    loadReport(params, (status, res) => {
      setData(res.data.data);
      setTotal(res.data.total);
      loading();
    });
  };

  const handleVisibleVideo = (url) => {
    setVisibleVideo(true);
    setVideoUrl(url);
  };

  const onCloseModalVideo = () => {
    setVisibleVideo(false);
  };

  const handleInfo = (id) => {
    setVisibleInfo(true);
    setVehicleId(id);
  };

  const onCloseInfo = () => {
    setVisibleInfo(false);
    setVehicleId(null);
  };

  const handlePhoneNumber = (info) => {
    const { imei, plateNo, code } = info;
    setInfo({
      imei,
      plateNo,
      code,
    });
    setVisiblePhoneNumber(true);
  };

  const onClosePhoneNumber = () => {
    setVisiblePhoneNumber(false);
    setInfo({ imei: "", plateNo: "", code: "" });
  };

  const handleTableChange = (pagination, sorter) => {
    const { page, page_size } = pagination;
    const order =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "desc";

    console.log("Sorted Info:", sorter);
    console.log("Current Status:", {
      field: sorter ? sorter.field : null,
      order: sorter.order,
    });

    setSorter({
      field: sorter ? sorter.field : null,
      order: sorter.order,
    });

    setLoading(true);
    getReport(
      {
        date_start: filterObj.startDate,
        date_end: filterObj.endDate,
        event: filterObj.type,
        time_range: filterObj.duration,
        user_id: auth.profile.id,
        vehicle_group:
          filterObj.vehicle_group === "all"
            ? "all"
            : filterObj.vehicle_group.split("_")[1],
        vehicle_visibility: auth.profile.vehicle_visibility,
        company_id: auth.profile.company_id,
        page,
        page_size,
        sort_field:
          sorter.order === "descend" || sorter.order === "ascend"
            ? sorter.field
            : "latestNoti",
        sort_order: order,
      },
      () => {
        setLoading(false);
      }
    );

    setPagination({
      page,
      page_size,
    });
  };

  useImperativeHandle(ref, () => ({
    getReport,
    pageSize: pagination.page_size,
    loading,
    setPagination,
  }));

  return (
    <Spin spinning={loading} ref={ref}>
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Table
            className="report-custom-table"
            columns={columns}
            dataSource={data}
            size="large"
            bordered
            pagination={false}
            onChange={(paginationInfo, _, sorter) => {
              handleTableChange(pagination, sorter);
            }}
          />
        </Col>
        <Col span={24} align="right">
          <Pagination
            size="small"
            showSizeChanger
            current={pagination.page}
            pageSize={pagination.page_size}
            onShowSizeChange={(_, size) => {
              handleTableChange({ page: 1, page_size: size }, sorter);
            }}
            onChange={(page, pageSize) => {
              handleTableChange({ page, page_size: pageSize }, sorter);
            }}
            pageSizeOptions={["10", "20", "50"]}
            total={total}
          />
        </Col>
      </Row>
      <ModalInfo
        visibleInfo={visibleInfo}
        vehicleId={vehicleId}
        onCloseInfo={onCloseInfo}
        handleVisibleVideo={handleVisibleVideo}
      />
      <ModalVideo
        videoUrl={videoUrl}
        visibleVideo={visibleVideo}
        onCloseModalVideo={onCloseModalVideo}
      />
      <ModalPhoneNumber
        visiblePhoneNumber={visiblePhoneNumber}
        info={info}
        onClosePhoneNumber={onClosePhoneNumber}
      />
    </Spin>
  );
});

export default Reports;
