import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import AuthorizeComponent from '../../../auth/components/AuthorizeComponent'
import { GeneralStyledContent } from '../../../../styled/common-styled'
import LangContext, { i18n } from 'modules/shared/context/langContext'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Spin,
  PageHeader,
  Tooltip,
  Button,
  Tag,
  Empty,
  Table,
  Divider,
  Tabs,
} from 'antd'

// Components
import TimeChart from './TimeChart'
import TableLog from './TableLog'

// API
import { monitorSDCardChartLog, removeLists } from '../actions'

// Lib
import {
  prepareDataForTable,
  prepareLogFormatArray,
  genBackgroundColor,
} from './MainFunction'

// CSS
import '../assets/css/index.css'

const { TabPane } = Tabs

class ViewDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      //*ข้อมูล table
      // เก็บ array ของวันที่และเดือนที่เลือก
      log_format_data: [],
      // columns
      table_columns: [],
      // ข้อมูล table log
      log_detail: [],

      //*ข้อมูลกราฟ
      title_page: '',
      chart_data: [],
      chart_ticks_x: [],
      chart_color: [
        {
          category: 'CH1_SDCard',
          color: '#FFCE56',
        },
        {
          category: 'CH2_SDCard',
          color: '#36A2EB',
        },
        {
          category: 'CH3_SDCard',
          color: '#9966FF',
        },
        {
          category: 'CH4_SDCard',
          color: '#ff7d00',
        },
        {
          category: 'GPS',
          color: '#00D090',
        },

        {
          category: 'CH1_ROM',
          color: '#ff006e',
        },
        {
          category: 'CH2_ROM',
          color: '#55dde0',
        },
        {
          category: 'CH3_ROM',
          color: '#ff00ff',
        },
        {
          category: 'CH4_ROM',
          color: '#7f3e00',
        },
      ],
      chart_ticks_y: [],
      all_category: [],

      list_vdo_sdcard: [],
      list_vdo_rom: [],
      video_type: {
        '1': 'front',
        '2': 'back',
        '3': 'left',
        '4': 'right',
        front: 'front',
        back: 'back',
      },
      columnsListVdo: [],

      pagination_sdcard: {
        current: 1,
        pageSize: 10,
      },
      pagination_rom: {
        current: 1,
        pageSize: 10,
      },
    }
  }

  componentDidMount() {
    const {
      vehicle_id,
      hardware_id,
      active_date,
      type,
    } = this.props.match.params

    if (vehicle_id && hardware_id && active_date && type) {
      this.handleShowChartDetail(vehicle_id, hardware_id, active_date, type)
    } else {
      this.redirectBack()
    }
  }

  componentWillUnmount() {
    this.onRemoveLists()
  }
  onRemoveLists = () => {
    this.props.removeLists()
  }

  redirectBack = () => {
    this.props.history.push('/monitor-sdcard')
  }

  //*เมื่อมีการเปลี่ยนแปลง state
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.monitorSDCard.check_chart_log !==
      nextProps.monitorSDCard.check_chart_log
    ) {
      let temp = nextProps.monitorSDCard.chart_log
      if (Object.keys(temp).length > 0) {
        const { active_date } = this.props.match.params
        let chart_data = temp.data || []
        let chart_ticks_x = temp.ticks_x || []
        let chart_ticks_y = temp.ticks_y || []
        let all_category = temp.all_category || []
        let vehicle_data = temp.vehicle_data || []
        let table_log = temp.table_log || []
        let list_vdo_sdcard = temp.list_vdo_sdcard || []
        let list_vdo_rom = temp.list_vdo_rom || []
        let title_page = ''

        //TODO:จัดรูปแบบ title
        if (vehicle_data.length > 0) {
          let vehicle_code = vehicle_data[0].vehicle_code || '-'
          let plate_no = vehicle_data[0].plate_no || '-'

          title_page = `${i18n[nextProps.language].d.detail} :
            (${vehicle_code}) ${plate_no} ${
            i18n[nextProps.language].d.date
          } ${moment(active_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}`
        }

        this.setState(
          {
            chart_data,
            chart_ticks_x,
            chart_ticks_y,
            all_category,
            title_page,
            list_vdo_sdcard,
            list_vdo_rom,
          },
          () => {
            this.prepareColumnsListVdo()
          }
        )

        //TODO:จัดรูปแบบข้อมูล table
        if (table_log.length > 0) {
          //จัดรูปแบบข้อมูล table
          this.setState(
            {
              log_detail: prepareDataForTable(table_log) || [],
              log_format_data:
                prepareLogFormatArray(
                  this.props.monitorSDCard.month_data,
                  moment(active_date, 'YYYY-MM-DD'),
                  moment(active_date, 'YYYY-MM-DD')
                ) || [],
            },
            () => {
              // *เตรียม columns ของ table view
              this.prepareColumns()
            }
          )
        }
      }
    }

    return true
  }

  // *แสดงรายละเอียดกราฟ
  handleShowChartDetail = (vehicle_id, hardware_id, active_date, type) => {
    this.props.monitorSDCardChartLog({
      vehicle_id: vehicle_id,
      hardware_id: hardware_id,
      date: active_date,
      type: type,
      accessToken: this.props.auth.accessToken,
    })
  }

  // *เตรียม columns ของ table view
  prepareColumns = () => {
    //TODO: สร้าง columns ของ table log
    const { log_format_data } = this.state
    const { active_date } = this.props.match.params
    let table_columns = []
    let all_columns = []

    let index_column = [
      {
        title: i18n[this.props.language].n.noReport,
        dataIndex: 'key',
        key: 'key',
        width: 50,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: <span>{record.number_index}</span>,
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ channel อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].v.vehicleData,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 150,
        align: 'center',
        fixed: 'left',
        // sorter: true,
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`(${record.vehicle_code}) ${record.plate_no}`}</span>
                    }
                    placement='topLeft'
                  >
                    {record.device_code ? (
                      <Button
                        type='link'
                        style={{
                          padding: '0',
                          whiteSpace: 'normal',
                          fontSize: '11px',
                        }}
                        size='small'
                        onClick={() => {
                          window.open(
                            `https://login.terminusfleet.com/receiver/test/show_simple_gps_data_v3.php?veh_search=&search=${record.device_code}&f_date=${active_date}&e_date=${active_date}&limit=3000&order=created_at+DESC`,
                            '_blank'
                          )
                        }}
                      >
                        <span>{`(${record.vehicle_code})`}</span>
                      </Button>
                    ) : (
                      <span>{`(${record.vehicle_code})`}</span>
                    )}

                    <span>{` ${record.plate_no}`}</span>
                  </Tooltip>

                  <br />
                  {record.device_code && (
                    <Tooltip
                      title={
                        <span>{`${
                          i18n[this.props.language].d.deviceCode
                        } : ${record.device_code || ''}`}</span>
                      }
                      placement='topLeft'
                    >
                      <span>{`${record.device_code || ''}`}</span>
                    </Tooltip>
                  )}
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].f.firmwareVersion,
        dataIndex: 'firmware_version',
        key: 'firmware_version',
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  <Tooltip
                    title={
                      <span>{`${
                        i18n[this.props.language].f.firmwareVersion
                      } : ${record.firmware_version || ''}`}</span>
                    }
                    placement='topLeft'
                  >
                    <span>{`${record.firmware_version || ''}`}</span>
                  </Tooltip>
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].l.lastmaintenanceAt,
        dataIndex: 'vehicle_id',
        key: 'vehicles.code',
        width: 120,
        align: 'center',
        fixed: 'left',
        render: (text, record, index) => {
          if (record.visible) {
            const obj = {
              children: (
                <div>
                  {record.maintenance_data && (
                    <Tooltip
                      title={
                        <>
                          <span>
                            {`${record.maintenance_data.type_name_th || '-'}`}
                          </span>
                          <br></br>
                          <span>{`${record.maintenance_data.status_name_th ||
                            '-'}`}</span>
                          <br></br>
                          <span>
                            {`${i18n[this.props.language].d.date} : ${record
                              .maintenance_data.date_display || '-'}`}
                          </span>
                          <br></br>
                          <span style={{ fontSize: '10px' }}>{`(${record
                            .maintenance_data.date_name || '-'})`}</span>
                        </>
                      }
                      placement='top'
                    >
                      <Button
                        type='link'
                        style={{ padding: '0' }}
                        size='small'
                        onClick={() => {
                          window.open(
                            `https://login.terminusfleet.com/map/maintenancedevice/view/id/${record.maintenance_data.id}`,
                            '_blank'
                          )
                        }}
                      >
                        <span>
                          {`${record.maintenance_data.type_name_th || '-'}`}
                        </span>
                      </Button>
                      <br></br>
                      <span>{`${record.maintenance_data.status_name_th ||
                        '-'}`}</span>
                      <br></br>
                      <span>
                        {`${i18n[this.props.language].d.date} : ${record
                          .maintenance_data.date_display || '-'}`}
                      </span>
                      <br></br>
                      <span style={{ fontSize: '10px' }}>{`(${record
                        .maintenance_data.date_name || '-'})`}</span>
                    </Tooltip>
                  )}

                  <div>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice?plate_no=${record.plate_no}`,
                          '_blank'
                        )
                      }}
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${
                          i18n[this.props.language].m.maintenanceDeviceHistory
                        }`}
                      </span>
                    </Button>
                    <br></br>
                    <Button
                      type='link'
                      style={{ padding: '0' }}
                      size='small'
                      onClick={() => {
                        window.open(
                          `https://login.terminusfleet.com/map/maintenancedevice/add?vehicle_id=${record.vehicle_id}`,
                          '_blank'
                        )
                      }}
                      disabled={
                        record.maintenance_data &&
                        record.maintenance_data.maintenances_status_id &&
                        [1, 2, 6, 7].includes(
                          record.maintenance_data.maintenances_status_id
                        )
                      }
                    >
                      <span style={{ fontSize: '10px' }}>
                        {`${i18n[this.props.language].m.maintenanceDevice}`}
                      </span>
                    </Button>
                  </div>
                </div>
              ),
              props: {
                align: 'center',
                rowSpan: record.number_of_channel || 1,
              },
            }
            return obj
          } else {
            // สำหรับ series อื่นๆ ไม่ต้องแสดงข้อมูลส่วนนี้เพราะ row แรก แสดงไปแล้ว
            const obj = {
              props: {
                align: 'center',
                rowSpan: 0,
              },
            }
            return obj
          }
        },
      },
      {
        title: i18n[this.props.language].c.channel,
        dataIndex: 'channel_name',
        key: 'channel_name',
        width: 50,
        align: 'center',
        render: (text, record, index) => {
          const obj = {
            children: (
              <Tooltip title={text} placement='topLeft'>
                <span>{text}</span>
              </Tooltip>
            ),
            props: {
              align: 'center',
              rowSpan: 1,
              colSpan: 1,
            },
          }

          return obj
        },
      },
    ]

    all_columns = index_column

    // วนลูปใน log_format_data['order_key']
    for (let i = 0; i < log_format_data['order_key'].length; i++) {
      let key = log_format_data['order_key'][i]
      let date_array = log_format_data[key].date_array
      let day_array = log_format_data[key].day_array

      // วนลูปใน date_array
      for (let j = 0; j < date_array.length; j++) {
        let date = date_array[j]
        let day = day_array[j]

        let column = {
          title: day,
          dataIndex: date,
          key: date,
          align: 'center',
          render: (text, record) => {
            return {
              props: {
                style: {
                  background:
                    record.type == 'camera' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(
                            item,
                            item['video_record_percent'],
                            item['rom_video_record_percent']
                          )
                        })
                      : record.type == 'online' && text
                      ? record[text].map((item, index) => {
                          return genBackgroundColor(
                            item,
                            item['online_percent'],
                            null
                          )
                        })
                      : '#FFF',
                },
              },
              children: (
                <div>
                  {text &&
                    record[text].map((item, index) => {
                      return record.type === 'camera' ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <div>
                            <Tooltip
                              title={
                                <>
                                  <Divider
                                    type='horizontal'
                                    style={{
                                      margin: '5px 0',
                                      padding: '0',
                                      color: 'white',
                                    }}
                                  >
                                    SDCard
                                  </Divider>
                                  <div>
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].p
                                            .percentageOfTotalVideoRecorded
                                        } : ${
                                          item['engine_on_minute'] === 0 // ไม่ได้ติดเครื่องเลย
                                            ? '-'
                                            : item['video_record_percent'] + '%'
                                        }`}
                                      </span>
                                    </div>

                                    {/* จำนวนนาทีของวิดีโอ/จำนวนนาทีที่ติดเครื่อง */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].t
                                            .totalMinutesOfVideoRecorded
                                        }/${
                                          i18n[this.props.language].t
                                            .totalMinutesEngineRunning
                                        } : ${
                                          item['engine_on_minute'] == 0
                                            ? '-'
                                            : `${item['online_minute']}/${item['engine_on_minute']}`
                                        }`}
                                      </span>
                                    </div>

                                    {/* ขนาดไฟล์ */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].f.fileSize
                                        } : ${item['file_size']} GB.`}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              <Link
                                to={`/monitor-sdcard/view-detail/sdcard/${record.vehicle_id}/${record.hardware_id}/${item['date']}`}
                                target='_blank'
                              >
                                <Button
                                  type='link'
                                  size='small'
                                  className='btn-sdcard'
                                >
                                  <span
                                    style={{
                                      fontSize: '11px',
                                      color: 'black',
                                    }}
                                  >
                                    {item['engine_on_minute'] == 0
                                      ? '-'
                                      : `${item['video_record_percent']}%`}
                                  </span>
                                </Button>
                              </Link>
                            </Tooltip>
                          </div>
                          <div>
                            <span>|</span>
                          </div>
                          <div>
                            <Tooltip
                              title={
                                <>
                                  <Divider
                                    type='horizontal'
                                    style={{
                                      margin: '5px 0',
                                      padding: '0',
                                      color: 'white',
                                    }}
                                  >
                                    Rom
                                  </Divider>

                                  <div>
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].p
                                            .percentageOfTotalVideoRecorded
                                        } : ${
                                          item['rom_engine_on_minute'] === 0 // ไม่ได้ติดเครื่องเลย
                                            ? '-'
                                            : item['rom_video_record_percent'] +
                                              '%'
                                        }`}
                                      </span>
                                    </div>

                                    {/* จำนวนนาทีของวิดีโอ/จำนวนนาทีที่ติดเครื่อง */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].t
                                            .totalMinutesOfVideoRecorded
                                        }/${
                                          i18n[this.props.language].t
                                            .totalMinutesEngineRunning
                                        } : ${
                                          item['rom_engine_on_minute'] == 0
                                            ? '-'
                                            : `${item['rom_online_minute']}/${item['rom_engine_on_minute']}`
                                        }`}
                                      </span>
                                    </div>

                                    {/* ขนาดไฟล์ */}
                                    <div>
                                      <span>
                                        {`${
                                          i18n[this.props.language].f.fileSize
                                        } : ${item['rom_file_size']} GB.`}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              }
                            >
                              <Link
                                to={`/monitor-sdcard/view-detail/rom/${record.vehicle_id}/${record.hardware_id}/${item['date']}`}
                                target='_blank'
                              >
                                <Button
                                  type='link'
                                  size='small'
                                  className='btn-sdcard'
                                >
                                  <span
                                    style={{
                                      fontSize: '11px',
                                      color: 'black',
                                    }}
                                  >
                                    {item['rom_engine_on_minute'] == 0
                                      ? '-'
                                      : `${item['rom_video_record_percent']}%`}
                                  </span>
                                </Button>
                              </Link>
                            </Tooltip>
                          </div>
                        </div>
                      ) : record.type === 'online' ? (
                        <>
                          <div>
                            <Tooltip
                              title={
                                <div>
                                  <span>
                                    {`${
                                      i18n[this.props.language].n
                                        .numberOfTimesDeviceOnline
                                    }/${
                                      i18n[this.props.language].n
                                        .numberOfTimesEngineStarted
                                    }`}
                                  </span>
                                </div>
                              }
                            >
                              <span style={{ fontSize: '11px' }}>
                                {`${item['online_count']}/${item['active_total']}`}
                              </span>
                            </Tooltip>
                          </div>
                        </>
                      ) : null
                    })}
                </div>
              ),
            }
          },
        }

        all_columns.push(column)
      }
    }
    table_columns = all_columns

    this.setState(
      {
        table_columns,
      },
      () => {}
    )
  }

  prepareColumnsListVdo = () => {
    //TODO:คอลัมน์ของ table รายละเอียด vdo
    let columnsListVdo = [
      {
        title: i18n[this.props.language].n.noReport,
        dataIndex: 'key',
        key: 'key',
        width: 30,
        align: 'center',
        render: (text, record, index) => {
          return (
            <>
              {record.log_type.endsWith('ROM') ? (
                <span>
                  {index +
                    1 +
                    (this.state.pagination_rom.current - 1) *
                      this.state.pagination_rom.pageSize}
                </span>
              ) : (
                <span>
                  {index +
                    1 +
                    (this.state.pagination_sdcard.current - 1) *
                      this.state.pagination_sdcard.pageSize}
                </span>
              )}
            </>
          )
        },
      },
      {
        title: i18n[this.props.language].t.timePeriod,
        dataIndex: 'get_list_vdo.id',
        key: 'get_list_vdo.id',
        width: 120,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{`${moment(record.start_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )} - ${moment(record.end_at, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm:ss'
              )}`}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].c.cameraPosition,
        dataIndex: 'channel',
        key: 'get_list_vdo.channel',
        width: 50,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              {record.log_type.startsWith('ISU') ? (
                <span>{`CH${record.channel}`}</span>
              ) : (
                <span>
                  {
                    i18n[this.props.language].cameraPosition[
                      this.state.video_type[text]
                    ]
                  }
                </span>
              )}
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].manageDashboardMonitoring.Channel,
        dataIndex: 'channel',
        key: 'get_list_vdo.channel',
        width: 30,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.channel}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].f.fileName,
        dataIndex: 'file_location',
        key: 'get_list_vdo.file_location',
        width: 120,
        align: 'center',
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.file_location}</span>
            </div>
          )
        },
      },
      {
        title: i18n[this.props.language].f.fileSize,
        dataIndex: 'file_size',
        key: 'get_list_vdo.file_size',
        align: 'center',
        width: 50,
        render: (text, record, index) => {
          return (
            <div>
              <span>{record.file_size}</span>
            </div>
          )
        },
      },
    ]

    this.setState(
      {
        columnsListVdo,
      },
      () => {}
    )
  }

  handleChangedPaginationSDCard = (page, pageSize) => {
    this.setState(
      {
        pagination_sdcard: {
          current: page,
          pageSize: pageSize,
        },
      },
      () => {}
    )
  }

  handleChangedPaginationROM = (page, pageSize) => {
    this.setState(
      {
        pagination_rom: {
          current: page,
          pageSize: pageSize,
        },
      },
      () => {}
    )
  }

  render() {
    const { check_chart_log, color_status } = this.props.monitorSDCard

    const {
      title_page,
      chart_data,
      chart_color,
      chart_ticks_x,
      chart_ticks_y,
      all_category,
      log_detail,
      table_columns,

      list_vdo_sdcard,
      list_vdo_rom,
      columnsListVdo,
    } = this.state

    return (
      <AuthorizeComponent {...this.props} matching_name='monitorSDCard'>
        <GeneralStyledContent>
          <div className='monitor-sdcard'>
            {/* header */}
            <PageHeader
              style={{ paddingLeft: '0px' }}
              title={
                <div className='header__text'>
                  {i18n[this.props.language].m.monitorSDCard}
                </div>
              }
            />

            {/* title */}
            <div>
              <span className='title-page'>
                <b>{title_page}</b>
              </span>
            </div>

            <div style={{ marginTop: '10px' }}>
              {color_status.map((item, index) => {
                return (
                  <>
                    <Tag
                      color={item.color}
                      key={index}
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                      }}
                    >
                      &nbsp;
                    </Tag>
                    <span className='font-tag-style'>{item.title}</span>
                    &nbsp;
                  </>
                )
              })}
            </div>

            {/* table log */}
            <div style={{ marginTop: '10px' }}>
              <Spin
                spinning={check_chart_log}
                tip={`${i18n[this.props.language].l.loading}...`}
              >
                {log_detail.length > 0 ? (
                  <>
                    <Row>
                      <Col span={24}>
                        {log_detail.length > 0 && (
                          <TableLog
                            data={log_detail || []}
                            column={table_columns}
                            className={'table-vdo'}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* แสดงกราฟ */}

                    <Row
                      style={{ marginTop: '20px' }}
                      type='flex'
                      align='middle'
                    >
                      <Col span={24}>
                        {chart_data && chart_data.length > 0 && (
                          <TimeChart
                            chart_data={chart_data}
                            chart_color={chart_color}
                            chart_ticks_x={chart_ticks_x}
                            chart_ticks_y={chart_ticks_y}
                            all_category={all_category}
                          />
                        )}
                      </Col>
                    </Row>

                    {/* แสดงผล table รายละเอียด vdo */}
                    <Row
                      style={{ marginTop: '20px' }}
                      type='flex'
                      align='middle'
                    >
                      <Col span={24}>
                        <Tabs defaultActiveKey='1'>
                          {list_vdo_sdcard.length > 0 && (
                            <TabPane tab={'SDCard'} key='1'>
                              <Table
                                size='small'
                                columns={columnsListVdo}
                                dataSource={list_vdo_sdcard || []}
                                scroll={{ x: 1000, y: 1000 }}
                                bordered
                                className='table-row'
                                pagination={{
                                  current: this.state.pagination_sdcard.current,
                                  pageSize: this.state.pagination_sdcard
                                    .pageSize,
                                  onChange: this.handleChangedPaginationSDCard,
                                  showSizeChanger: true,
                                  pageSizeOptions: ['10', '20', '50', '100'],
                                  onShowSizeChange: (current, pageSize) => {
                                    this.handleChangedPaginationSDCard(
                                      current,
                                      pageSize
                                    )
                                  },
                                }}
                              ></Table>
                            </TabPane>
                          )}

                          {list_vdo_rom.length > 0 && (
                            <TabPane tab={'ROM'} key='2'>
                              <Table
                                size='small'
                                columns={columnsListVdo}
                                dataSource={list_vdo_rom || []}
                                scroll={{ x: 1000, y: 1000 }}
                                bordered
                                className='table-row'
                                pagination={{
                                  current: this.state.pagination_rom.current,
                                  pageSize: this.state.pagination_rom.pageSize,
                                  onChange: this.handleChangedPaginationROM,
                                  showSizeChanger: true,
                                  pageSizeOptions: ['10', '20', '50', '100'],
                                  onShowSizeChange: (current, pageSize) => {
                                    this.handleChangedPaginationROM(
                                      current,
                                      pageSize
                                    )
                                  },
                                }}
                              ></Table>
                            </TabPane>
                          )}
                        </Tabs>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </>
                )}
              </Spin>
            </div>
          </div>
        </GeneralStyledContent>
      </AuthorizeComponent>
    )
  }
}

export default connect(
  ({ monitorSDCard, ui: { language }, auth, auth: { profile } }) => ({
    monitorSDCard,
    language,
    auth,
    profile,
  }),
  {
    monitorSDCardChartLog: monitorSDCardChartLog.request,
    removeLists,
  }
)(ViewDetail)
